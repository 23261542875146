module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"zSP3diixU4W44BRoc8Km2ATWZAbBCbTy","devKey":"xgeVPAFxLvH8Rhj3KsXkUusGOHpEto6V","trackPage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ac47725c497b50eb725b5519e62eb336"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://use.typekit.net"],"web":[{"name":"roc-grotesk","file":"https://use.typekit.net/cqz1jpk.css"},{"name":"roc-grotesk-wide","file":"https://use.typekit.net/cqz1jpk.css"},{"name":"adlery-pro","file":"https://use.typekit.net/cqz1jpk.css"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
