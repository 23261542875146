import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { navigate } from 'gatsby'
import smoothScroll from 'smoothscroll-polyfill'
import './src/styles/global.css'

const fallbackUri = '/fractional-ownership/'

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || fallbackUri, { replace: true })
}

type Data = { clientID?: string; domain?: string; redirectUri?: string }

export const wrapRootElement = ({ element }) => {
  const data: Data = {
    redirectUri: window.location.origin,
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
    domain: process.env.GATSBY_AUTH0_DOMAIN,
  }

  if (!data?.domain || !data?.clientID) return element

  return (
    <Auth0Provider
      domain={data.domain}
      clientId={data.clientID}
      redirectUri={data.redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {element}
    </Auth0Provider>
  )
}

const segmentPageNames = {
  '/fractional-ownership/': 'FractionalOwnership',
}

const mapLocationToPage = (location: Location) => {
  if (location.pathname in segmentPageNames) {
    return segmentPageNames[location.pathname]
  }
}

export const onRouteUpdate = ({ location }: { location: Location }) => {
  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.page({
      type: 'page',
      name: mapLocationToPage(location),
      properties: {
        title: document.title,
        url: location.href,
      },
      referrer: document.referrer,
      search: location.search,
    })
  }
}

smoothScroll.polyfill()
